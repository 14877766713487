<template>
  <div class="invoiceCon">
    <!-- 等待处理 提交失败-->
    <div class="statusText">
      <van-icon
        :name="resultInfo.type == 1 ? 'clock' : 'clear'"
        size="100"
        :color="resultInfo.type == 1 ? '#2CD7AA' : '#F4333B'"
      />
      <div class="text">{{ resultInfo.type == 1 ? "等待处理" : "提交失败" }}</div>
      <div class="statusResult">
        {{ resultInfo.type == 1 ? "已提交申请，请等待处理" : `${resultInfo.msg}` }}
      </div>
    </div>
    <div class="returnbox">
      <van-button class="returnPage" @click="toBack">
        {{ "返回" + "(" + times + "s" + ")" }}
      </van-button>
    </div>
  </div>
</template>

<script>
import {
  getCurrentInstance,
  onMounted,
  onUnmounted,
  reactive,
  toRefs
} from "vue";
import { setStore } from "@/utils/store";
import { useRoute } from "vue-router";
export default {
  setup() {
    const state = reactive({
      timmer: null,
      times: 5,
			resultInfo: {
        type:0,
				msg:''
			},
      theme: "" //主题名称
    });
    // 这里的ctx  类似于vue2的this
    const { proxy: ctx } = getCurrentInstance();
		// 路由 this.$router
    const { $router : router, $store: store } = ctx;
		//调用useRoute,获取页面携带的参数
    const route = useRoute();
		Object.assign(state.resultInfo, route.query)
    const methods = {
      // 倒计时
      handleCountDown() {
        state.timmer = setInterval(() => {
          if (state.times == 0) {
            ctx.toBack();
            clearInterval(state.timmer);
            return;
          }
          state.times--;
        }, 1000);
      },
      // 返回账单中心
      toBack() {
        setStore({
          name: "tempSetMeal",
          content: true,
          type: "session"
        });
				router.push({
					name : `personal`
				})
      }
    };
    onMounted(() => {
      //获取主题名称
      state.theme = store.state.common.theme;
      ctx.handleCountDown();
    });
    onUnmounted(() => {
      clearInterval(state.timmer);
    });
    return {
      ...toRefs(state),
      ...methods
    };
  }
};
</script>

<style lang="scss" scoped>
@import "@/style/theme/index";
.invoiceCon {
  height: 100vh;
  margin: 0 auto;
  background: #ffffff;
  .statusText {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-left: 20px;
    padding-top: 10px;
    .text {
      padding-left: 6px;
      font-weight: bold;
    }
    .statusResult {
      margin-top: 20px;
      color: #c0c2c7;
    }
  }
  .title {
    margin: 30px 0px;
  }
  .returnbox {
    width: 690px;
    margin: 30px auto;
    .returnPage {
      width: 100%;
			color: #fff;
			@include background_color("background_color1");
    }
  }
}
.van-cell {
  padding: 16px 16px;
}
</style>
